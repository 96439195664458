import '../styles/Contact.css'
import Link from '@material-ui/core/Link';
import FaceBookIcon from'@material-ui/icons/Facebook';
import Email from '@material-ui/icons/Email';


export const Contact = () => {

    return(
        <>
        <div className='contact'>
            <div className="mapLocation">
            <div>
                <iframe width="750" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=59%20High%20St,%20Brownhills,%20Walsall%20WS8%206HH+(morgans%20coffee%20shop)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
               
                </iframe>
                    </div>
            </div>

            <div className='socialMedia'>
                <div className='facebook'>
                    <Link href='https://www.facebook.com/p/Morgans-coffee-shop-100075947683743/' target='_blank' rel='noopener noreferrer'>
                        <FaceBookIcon />
                    </Link>
                </div>
                <div className='email'>
                    <Link href='mailto:terry.morgan@live.co.uk'>
                        <Email />
                    </Link>
                </div>
            </div>

            <div className='phone'>
                <p>
                    For any enquires, please ring Terry on: <span className='number'>07368260224 / 07368381457</span> 
                    or <a href='/booking' className='link'>Click Here</a>
                </p>
            </div>
        </div>
        </>
    );
}