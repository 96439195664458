import '../styles/Home.css';
import coffeeCake from '../assets/coffeeCake.jpg';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image  from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import justEat from '../assets/justEat.png';
import uberEats from '../assets/uberEats.png';
import deliveroo from '../assets/deliveroo.png';
import Button from 'react-bootstrap/Button';
import shmoo from '../assets/shmoo.png';
import foodHygiene from '../assets/foodHygiene.png';
import {CarouselHelper} from '../components/CarouselHelper';
import catering1 from '../assets/catering1.JPG';
import catering2 from '../assets/catering2.JPG';
import catering3 from '../assets/catering3.JPG';
import {Link} from 'react-router-dom';



export const Home = () => {

  return (
    <>
    <div className='header'>
    <header style={{ paddingLeft: 0 }}>
    <div
      className='p-5 text-center bg-image'
      style={{ backgroundImage: `url(${coffeeCake})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center 37%', 
      height: 250,
      }}
    >
      </div>
  </header>
  </div>
  


  <Container>
    <Row>
      <Col xs={12} md={7} >
        <div className='welcome'>
          <h1> <span className='welcomeLogo'>Morgan's</span> Coffee Shop</h1>
            <div>
              <h2>Local. Fresh. Friendly.</h2>
            </div>
        </div>
      </Col>
      <Col xs={4} md={5} >
        <a href='https://www.scoresonthedoors.org.uk/business/morgans-coffee-shop-walsall-west-midlands-1498290.html'
        target='_blank' rel='noreferrer noopener'>
          <Image src={foodHygiene} fluid  className='foodHygiene'/>
        </a>
      </Col>
    </Row>

    <Row>
      <Col xs={6} md={4} >
        <div className='promo'>
          <h3> Afternoon Tea &  Personalised Catering </h3>
          <p> Availability for afternoon tea & a large vaiety of personlised catering avaliable. 
             <span className='promoRating'>(Come and see why we are rated 4.8/5 on Google!) </span>
          </p>
          <Link to='/booking'>
            <Button variant="outline-success" className='promoButton'>Inquire here</Button>
          </Link>
        </div>
      </Col>
      <Col xs={6} md={4}>
        <div className='menuPromo'>
          <h3> Menu </h3>
          <p> View our menu of fresh food and fairtrade coffee.</p>
          <Link to='menu'>
            <Button variant="outline-success" className='promoButton'>Menu</Button>
          </Link>
        </div>
      </Col>
      <Col xs={12} md={4} >
        <div className='facebook'>
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fp%2FMorgans-coffee-shop-100075947683743%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
      </Col>
    </Row>
  </Container>


  
  <div className='catering'>
    <Container>
      
        <Row>
          <Col xs={12} md={6} className='cateringText'>
            <h2>Catering</h2>
            <p> A wide variety of personlised catering avaliable <br/>for your every need.</p>
            <ul>
              <li>Wedding Receptions</li>
              <li>Wakes</li>
              <li>Birthday Parties</li>
              <li>Many More...</li>
            </ul>
            <Link to='/booking'>
              <Button variant="outline-success"  className='promoButton'>Inquire here</Button>
            </Link>
          </Col>
          <Col xs={4} md={2}>
              <div className='pillar-image-overlay'>
                <Image src={catering1} fluid rounded/>
              </div>
          </Col>
          <Col xs={4} md={2}>
              <div className='pillar-image-overlay' id='pillarImageMiddle'>
                <Image src={catering2} fluid rounded/>
              </div>
          </Col>
          <Col xs={4} md={2}>
              <div className='pillar-image-overlay'>
                <Image src={catering3} fluid rounded/>
              </div>
          </Col>
        </Row>
      
    </Container>
  </div>

  <Container>
  <Row>
    <Col xs={6} md={3} className='logos'>
      <Image src={justEat}  fluid />
    </Col>
    <Col xs={6} md={3} className='logos'>
      <Image src={uberEats}  fluid/>
    </Col>
    <Col xs={6} md={3} className='logos'>
      <Image src={deliveroo}  fluid/>
    </Col>
    <Col xs={6} md={3} className='logos'>
      <Image src={shmoo}  fluid/>
    </Col>
  </Row>
  </Container>


      <div className='carosuelText'>
        <h2> A sneak peek at your next visit:</h2>
      </div>
  <div className='container'>
  <div className='row'>
    <div className='col'>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='carousel'>
          <CarouselHelper />
        </div>
      </div>
    </div>
  </div>
</div>
  </>
  
  );



};
