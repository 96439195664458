import '../styles/Booking.css';



export const Booking = () => {
    return(
      


        <>
        <div className='bookingText'>
            <h4>
                For any Afternoon Tea, Catering bookings or general inquiries, please fill out the below form. 
            </h4>
        </div>
        <div className='form'>
            <form action = 'https://api.web3forms.com/submit' method='POST'>
                <input type="hidden" name="access_key" value="13f7ccea-73c8-4c5b-9542-d9a9dc71b09c" />

                <input type="text" name="name" placeholder="Your Name" required />
                <input type="email" name="email" placeholder="Your Email" required />
                <textarea name="message" placeholder="Your Message" required></textarea>
                <div class="h-captcha" data-captcha="true"></div>
                <button type="submit">Submit</button>       

            </form>
        </div>

        <script src="https://web3forms.com/client/script.js" async defer></script> 

        </> 
  
    );
};