import "../styles/Footer.css";


export const Footer = () => {
    return (
        <div className="footer">
                <p>
                    &copy; {new Date().getFullYear()} Morgan's
                </p>
            </div>
    );
};