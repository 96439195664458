import '../styles/Menu.css';
import MenuBookIcon from '@mui/icons-material/MenuBook';


export const Menu = () => {
    return(
        <>
        <div className='menu'>
            <h1>Please browse our menu via the below link:</h1>

        </div>
        <div className='icon'>
            <a href='https://drive.google.com/file/d/19LmYceO-UdsSwsz6GSTWUVFTDi2R7pN8/view'
            target='_blank' rel='noreferrer noopener'>
                <MenuBookIcon />     
            </a>
        </div>
        </>
    )
}