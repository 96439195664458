import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import "../styles/Header.css";

export const Header = () => {
    const [expandNavbar, setExpendNavbar] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setExpendNavbar(false);
    }, [location]);

    return (
        <>
        <div className="header">
            <div >
                <a href="/"><h1 className="logo"> <span id="logoName">Morgan's</span> Coffee Shop</h1></a>
            </div>
            <div className="navbar" id={expandNavbar ? "open" : "close"}>
                <div className="toggleButton">
                    <button onClick={() => {
                        setExpendNavbar((prev) => !prev)
                        }}
                    >
                        <ReorderIcon /> 
                    </button>
                </div>
                <div className="links">                   
                    <Link to={"/menu"}> Menu </Link>
                    <Link to={"/contact"}> Contact Us </Link>
                    <div className="book">
                        <Link to={'/booking'}> Catering </Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};