import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import shopMain from '../assets/shopMain.jpg';
import cakes from '../assets/cakes.jpg';
import shopFloor from '../assets/shopFloor.jpg';


export const CarouselHelper = () => {
    return(
        <Carousel>
            <Carousel.Item interval={4000}>
                <Image
                className="w-100 img-fluid"
                style={{maxHeight: '550px'}}
                src={shopMain}
                alt="First slide"
                />
                <Carousel.Caption>
                <h3></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <Image
                className="w-100 img-fluid"
                style={{maxHeight: '550px'}}
                src={cakes}
                alt="Second slide"
                />
                <Carousel.Caption>
                <h3></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <Image
                className="w-100 img-fluid"
                style={{maxHeight: '550px'}}
                src={shopFloor}
                alt="Third slide"
                />
                <Carousel.Caption>
                <h3></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item interval={4000}>
                <Image
                className="w-100 img-fluid"
                style={{maxHeight: '550px'}}
                src={coffeeCakes}
                alt="Third slide"
                />
                <Carousel.Caption>
                <h3></h3>
                <p></p>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
    )
}