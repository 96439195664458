import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import{Home} from './pages/Home';
import{Menu} from './pages/Menu';
import{Contact} from './pages/Contact';
import {Header} from './components/Header';
import {Booking} from './pages/Booking';
import {Footer} from './components/Footer';
import './App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/booking' element={<Booking />} />
        </Routes>
          <Footer />
      </Router>


    </div>
  );
}

export default App;
